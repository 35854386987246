<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="bagsSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
                @change="restartIds"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="bagsCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
            
            <v-spacer />
            <v-text-field
              id="bagsSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
              @change="restartIds"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="showConfiguration"
                >
                  <v-icon class="yellow-color-text">mdi-cog</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`actions.configuration`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="user.parentType === 'Company::Central'"
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
            <refresh-button v-if="!isMobile"></refresh-button>
            <export-button :headers="userConfiguration" :items="filtered ? filteredData : data" :file-name="'Bolsas'" :search="search"></export-button>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  :color="(filtered && viewType === 'bags') ? 'yellow' : 'white'"
                  text
                  icon
                  ma-0
                  rounded
                  outlined
                  style="margin: 0;"
                  v-on="on"
                  @click="() => changeType(viewType === 'filters' ? 'bags' : 'filters')"
                >
                  <v-icon>{{ viewType === 'bags' ? 'mdi-filter' : 'mdi-food-croissant' }}</v-icon>
                </v-btn>
              </template>
              <span class="white--text">{{ $t(`${objectName.multiple}.changeFilter`) }}</span>
            </v-tooltip>
          </v-toolbar>

          <v-card-text class="px-2" v-if="viewType ==='bags'">
            <v-data-table
              :headers="renderHeaders"
              :items="filtered ? filteredData : data"
              :search="search"
              :sort-by="['state', 'reference']"
              :sort-desc="[false, false]"
              fixed-header
              height="800"
              :items-per-page="-1"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
              :custom-filter="bagsFilter"
              
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <!-- <td>{{ item.reference }}</td>
                    <td>{{ item.localCompany }}</td>
                    <td>{{ item.depositoryCompany }}</td>
                    <td>{{ item.fieldBatchName }}</td>
                    <td>{{ item.productName }}</td>
                    <td>{{ item.cycleName }}</td>
                    <td>{{ item.sensorizationType }}</td>
                    <td>{{ item.realWeight }}</td>
                    <td>{{ item.contractNumber }}</td>
                    <td>{{ item.contractNumberComm }}</td>
                    <td>{{ item.kitName }}</td>
                    <td><v-chip :color="item.stateValue === 'active' ? 'success' : 'warning'">{{ item.state }}</v-chip></td>
                    <td>{{ date(item.createdAt) }} {{ time(item.createdAt) }}</td>
                    <td>{{ item.bagConfigured }}</td> -->
                    <td v-for="(header, index) in userConfiguration" :key="`${header.value}-${item.id}`">
                      <span :style="index === 0 ? 'font-weight: bold;' : ''" v-html="header.format(item[header.value])"></span>
                    </td>
                    <td style="text-align: right; padding: 0;">
                      <v-btn
                        text
                        icon
                        rounded
                        color="info"
                        @click="showItem(item.id)"
                      >
                        <v-icon>mdi-file-find-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="user.parentType == 'Company::Central' || item.state == 'by_assigned'"
                        text
                        icon
                        rounded
                        color="warning"
                        @click="editItem(item.id)"
                      >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
  
                      <v-btn
                        v-if="(user.parentType != 'Company::Depository' && item.state == 'by_assigned') || (user.parentType == 'Company::Central' && item.stateValue != 'active' && item.stateValue != 'on_load')"
                        text
                        icon
                        rounded
                        color="error"
                        @click="deleteItem(item.id)"
                      >
                        <v-icon>mdi-file-cancel-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td :colspan="renderHeaders.length">
                      <hr>
                    </td>
                  </tr>
                  <tr v-if="renderHeadersWeightIndex !== -1">
                    <td>Total:</td>
                    <template v-for="(header, index) in renderHeaders">
                      <td :key="`${header.value}-footer`">
                        <template v-if="index === renderHeadersWeightIndex -1">
                          {{ items.reduce((a, b) => a + (b.realWeight || 0), 0) }}
                        </template>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="renderHeaders.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
          <v-card-text v-if="viewType === 'filters'">
            <v-layout row wrap pa-1 align-center justify-center>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="companyLocal"
                  :items="companyLocals"
                  :label="$t(`${objectName.multiple}.companyLocal`)" 
                  :color="inputColor"
                  item-text="fiscalName"
                  item-value="fiscalName"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="depositoryCompany"
                  :items="companyDepositories"
                  :label="$t(`${objectName.multiple}.depositoryCompany`)" 
                  :color="inputColor"
                  item-text="fiscalName"
                  item-value="fiscalName"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="fieldbatch"
                  :items="fieldbatches"
                  :label="$t(`${objectName.multiple}.fieldBatchId`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="name"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="product"
                  :items="products"
                  :label="$t(`${objectName.multiple}.productId`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="name"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="cycle"
                  :items="cycles"
                  :label="$t(`${objectName.multiple}.cycleId`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="name"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="sensorizationType"
                  :items="sensorizationTypes"
                  :label="$t(`${objectName.multiple}.sensorizationType`)" 
                  :color="inputColor"
                  item-text="text"
                  item-value="value"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="state"
                  :items="states"
                  :label="$t(`${objectName.multiple}.state.title`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="name"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="alertsConfigured"
                  :items="alertsConfiguredOptions"
                  :label="$t(`${objectName.multiple}.alertsConfigured`)" 
                  :color="inputColor"
                  item-text="text"
                  item-value="value"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="kitFilter"
                  :items="kits"
                  :label="$t(`${objectName.multiple}.kitId`)" 
                  :color="inputColor"
                  item-text="reference"
                  item-value="reference"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions v-if="viewType === 'filters'">
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="cleanFilters"
            >
              {{ $t(`${objectName.multiple}.cleanFilters`) }}
            </v-btn>
            <v-btn
              color="primary"
              @click="filterData"
            >
              {{ $t(`${objectName.multiple}.filter`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title>
        <v-card-text style="flex: 1;" class="pa-0">
          <v-tabs 
            background-color="primary"
            class="text--white"
            slider-color="primary"
            grow
            dark
          >
            <v-tab>{{ $t(`${objectName.multiple}.tabs.basic`) }}</v-tab>
            <v-tab>{{ $t(`${objectName.multiple}.tabs.sensors`) }}</v-tab>
            <v-tab>{{ $t(`${objectName.multiple}.tabs.rules`) }}</v-tab>
            <v-tab>{{ $t(`${objectName.multiple}.tabs.media`) }}</v-tab>
            <v-tab v-if="user.parentType === 'Company::Central'">{{ $t(`${objectName.multiple}.tabs.state`) }}</v-tab>
            <v-tab v-if="object.id !== undefined && ifCompanyCentral()">{{ $t(`${objectName.multiple}.tabs.historical`) }}</v-tab>
            
            <v-tab-item key="1" class="px-5">
              <v-layout row wrap pa-1 align-center justify-center>
                <v-flex class="pl-2 pr-2" xs12>
                  <v-text-field
                    v-model="object.reference"
                    :label="$t(`${objectName.multiple}.reference`)" 
                    :disabled="!isEdittable()"
                    :color="inputColor"
                    :error="objectErrors.reference !== undefined"
                    :error-messages="parseErrors(objectErrors.reference)"
                  />
                </v-flex>

                <!-- <v-flex class="pl-2 pr-2" xs12 sm6>
                    <v-text-field
                      v-model="object.localContactName"
                      :label="$t(`${objectName.multiple}.localContactName`)" 
                      :disabled="!isEdittable()"
                      :color="inputColor"
                      :error="objectErrors.localContactName !== undefined"
                      :error-messages="parseErrors(objectErrors.localContactName)"
                    />
                  </v-flex>

                  <v-flex class="pl-2 pr-2" xs12 sm6>
                    <v-text-field
                      v-model="object.localContactPhone"
                      :label="$t(`${objectName.multiple}.localContactPhone`)" 
                      :disabled="!isEdittable()"
                      :color="inputColor"
                      :error="objectErrors.localContactPhone !== undefined"
                      :error-messages="parseErrors(objectErrors.localContactPhone)"
                    />
                  </v-flex> -->
                
                <v-flex class="pl-2 pr-2" xs12 sm3>
                  <v-text-field
                    v-model="object.estimatedWeight"
                    :label="$t(`${objectName.multiple}.estimatedWeight`)" 
                    :disabled="!isEdittable()"
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    :color="inputColor"
                    :error="objectErrors.estimatedWeight !== undefined"
                    :error-messages="parseErrors(objectErrors.estimatedWeight)"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm3>
                  <v-text-field
                    v-model="object.realWeight"
                    :label="$t(`${objectName.multiple}.realWeight`)" 
                    :disabled="!isEdittable()"
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    :color="inputColor"
                    :error="objectErrors.realWeight !== undefined"
                    :error-messages="parseErrors(objectErrors.realWeight)"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm3>
                  <v-text-field
                    v-model="object.longitude"
                    :label="$t(`${objectName.multiple}.longitude`)" 
                    :disabled="!isEdittable()"
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    :color="inputColor"
                    :error="objectErrors.longitude !== undefined"
                    :error-messages="parseErrors(objectErrors.longitude)"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm3>
                  <v-text-field
                    v-model="object.distanceFromTheNode"
                    :label="$t(`${objectName.multiple}.distanceFromTheNode`)" 
                    :disabled="!isEdittable()"
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    :color="inputColor"
                    :error="objectErrors.distanceFromTheNode !== undefined"
                    :error-messages="parseErrors(objectErrors.distanceFromTheNode)"
                  />
                </v-flex>
                
                <v-flex class="pl-2 pr-2" xs12 sm6 md4>
                  <v-select
                    v-model="object.fieldBatchId"
                    :items="fieldBatches"
                    :label="$t(`${objectName.multiple}.fieldBatchId`)"
                    :disabled="!isEdittable()" 
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                    @change="reDrawField"
                  />
                </v-flex>
                
                <v-flex class="pl-2 pr-2" xs12 sm6 md4>
                  <v-select
                    v-model="object.productId"
                    :items="products"
                    :label="$t(`${objectName.multiple}.productId`)" 
                    :disabled="!isEdittable()"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 md4>
                  <v-select
                    v-model="object.cycleId"
                    :items="cycles"
                    :label="$t(`${objectName.multiple}.cycleId`)" 
                    :disabled="!isEdittable()"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-select
                    v-model="object.financialAdvances"
                    :items="generalOptions"
                    :label="$t(`${objectName.multiple}.financialAdvances`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="text"
                    :error="objectErrors.financialAdvances !== undefined"
                    :error-messages="parseErrors(objectErrors.financialAdvances)"
                    item-value="value"
                  />
                </v-flex>
                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-select
                    v-model="object.weightGaranty"
                    :items="generalOptions"
                    :label="$t(`${objectName.multiple}.weightGaranty`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="text"
                    :error="objectErrors.weightGaranty !== undefined"
                    :error-messages="parseErrors(objectErrors.weightGaranty)"
                    item-value="value"
                  />
                </v-flex>
                <v-flex class="pl-1 pr-1" xs12>
                  <v-textarea
                    v-model="object.observation"
                    :label="$t(`${objectName.multiple}.observation`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.observation !== undefined"
                    :error-messages="parseErrors(objectErrors.observation)"
                  />
                </v-flex>
                <v-flex v-if="object.id !== undefined" class="pl-1 pr-1" xs12>
                  <v-text-field
                    v-model="object.createdAtValue"
                    :label="$t(`${objectName.multiple}.createdAt`)"
                    :disabled="true"
                    :color="inputColor"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="object.sensorizationType"
                    :items="sensorizationTypes"
                    :label="$t(`${objectName.multiple}.sensorizationType`)" 
                    :disabled="!isEdittable()"
                    :color="inputColor"
                    item-text="text"
                    item-value="value"
                  />
                </v-flex>
                <v-flex v-if="object.sensorizationType !== ''" xs12 :class="object.sensorizationType === 'ST' ? 'sm6 pr-2': ''">
                  <v-text-field
                    v-model="object.contractNumberComm"
                    type="number"
                    :label="$t(`${objectName.multiple}.contractNumberComm`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.contractNumberComm !== undefined"
                    :error-messages="parseErrors(objectErrors.contractNumberComm)"
                  />
                </v-flex>
                <v-flex v-if="object.sensorizationType === 'ST'" class="pl-2" xs12 sm6>
                  <v-text-field
                    v-model="object.contractNumber"
                    type="number"
                    :label="$t(`${objectName.multiple}.contractNumber`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.contractNumber !== undefined"
                    :error-messages="parseErrors(objectErrors.contractNumber)"
                  />
                </v-flex>
                <v-flex xs12>
                  <h5>
                    Geocerca
                  </h5>
                </v-flex>
                <template v-for="(point, index) in object.geofence">
                  <v-flex :key="index" class="pl-2 pr-2" xs12>
                    <v-layout row wrap>
                      <v-flex class="pl-2 pr-2" xs12 md6>
                        <v-text-field
                          v-model="point.latitude"
                          :label="$t(`${objectName.multiple}.geofences.latitude`)" 
                          :disabled="!isEdittable()"
                          type="number"
                          onkeydown="return event.keyCode !== 69"
                          :color="inputColor"
                          disbale="true"
                        />
                      </v-flex>
                      <v-flex class="pl-2 pr-2" xs12 md6>
                        <v-text-field
                          v-model="point.longitude"
                          :label="$t(`${objectName.multiple}.geofences.longitude`)" 
                          :disabled="!isEdittable()"
                          type="number"
                          onkeydown="return event.keyCode !== 69"
                          :color="inputColor"
                          disbale="true"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
                <v-flex xs12>
                  <div id="bagMap" style="width: 100%; height: 250px;" />
                </v-flex>
              </v-layout>
            </v-tab-item>

            <v-tab-item key="2" class="px-5">
              <v-layout row wrap pa-1 align-center justify-center class="pt-2 pb-2">
                <v-flex xs12>
                  <v-autocomplete
                    v-model="object.kitId"
                    :items="selectableKits()"
                    :label="$t(`${objectName.multiple}.kitId`)" 
                    :disabled="!isEdittable()"
                    :color="inputColor"
                    item-text="reference"
                    item-value="id"
                    @change="setKit"
                  />
                </v-flex>
                <v-flex v-if="kit.id != undefined" xs12 class="pa-2">
                  {{ $t(`${objectName.multiple}.kitInformation`) }}
                </v-flex>
                <v-flex v-if="kit.id != undefined" xs12 class="pa-2">
                  <template
                    v-for="(sensor, index) in kit.loraSensors"
                  >
                    <v-layout row wrap :key="index">
                      <v-flex xs12 sm6 class="pr-2">
                        <v-text-field
                          v-model="sensor.name"
                          :label="$t(`${objectName.multiple}.loraSensors.name`)" 
                          :color="inputColor"
                          :error="objectErrors.loraSensors !== undefined"
                          :disabled="true"
                          :error-messages="parseErrors(objectErrors.loraSensors)"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 class="pl-2">
                        <v-text-field
                          v-model="sensor.identifier"
                          :label="$t(`${objectName.multiple}.identifier`)" 
                          :color="inputColor"
                          :error="objectErrors.loraSensors !== undefined"
                          :disabled="true"
                        />
                      </v-flex>
                    </v-layout>
                  </template>

                </v-flex>
              </v-layout>

              <!--  <v-layout v-else>
                <v-flex xs12>
                  <v-data-table
                    :headers="[
                      {
                        text: this.$i18n.t(`loraSensors.name`),
                        align: 'left',
                        sortable: true,
                        value: 'name'
                      },
                      {
                        text: this.$i18n.t(`loraSensors.deviceEui`),
                        align: 'left',
                        sortable: true,
                        value: 'deviceEui'
                      },
                    ]"
                    :items="loraSensors.filter((item, index, arr) => { return object.loraSensors.includes(item.id) })"
                    next-icon="mdi-chevron-right"
                    prev-icon="mdi-chevron-left"
                  > 
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.deviceEui }}</td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout> -->
            </v-tab-item>

            <v-tab-item key="3" class="px-5">
              <v-layout row wrap>
                <v-flex v-if="isEditting" xs12 class="mt-2" style="text-align: right;">
                  <v-btn
                    color="white--text"
                    text
                    style="margin: 0;"
                    @click="addRule"
                  >
                    {{ $t('bags.ruleNotifications.add') }}
                    &nbsp;
                    <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-flex>

                <v-flex xs12>
                  <v-data-table
                    :headers="rulesHeaders"
                    :disable-pagination="true"
                    hide-default-footer
                    :items="object.ruleNotifications"
                    :items-per-page="-1"
                    :footer-props="{
                      'next-icon': 'mdi-chevron-right',
                      'prev-icon': 'mdi-chevron-left'
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="(item, index) in items" :key="item.id">
                        <td>{{ item.name }}</td>
                       
                        <td>{{ $t(`bags.ruleNotifications.calculation.${item.calculation.type.toLowerCase()}`) }}</td>
                        <td style="text-align: right; padding: 0;">
                          <v-btn
                            text
                            icon
                            rounded
                            color="warning"
                            @click="editRule(index)"
                          >
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                          </v-btn>

                          <v-btn
                            v-if="isEditting"
                            text
                            icon
                            rounded
                            color="error"
                            @click="deleteRule(index)"
                          >
                            <v-icon>mdi-file-cancel-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>

            <v-tab-item key="4" class="px-5">
              <v-layout row wrap>
                <v-flex v-if="isEditting" xs12 class="mt-2" style="text-align: right;">
                  <upload-button ref="uploadBtn" :title="$t('bags.media.add')" @file-update="uploadFile">
                    <template slot="icon-left">
                      <v-icon left>mdi-plus-circle-outline</v-icon>
                    </template>
                  </upload-button>
                </v-flex>

                <v-flex xs12>
                  <v-data-table
                    :headers="mediaHeaders"
                    :items="object.media"
                    :items-per-page="-1"
                    :footer-props="{
                      'next-icon': 'mdi-chevron-right',
                      'prev-icon': 'mdi-chevron-left'
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td style="text-align: right; padding: 0;">
                          <v-btn
                            text
                            icon
                            rounded
                            color="info"
                            target="_blank"
                            :href="item.uri"
                          >
                            <v-icon>mdi-file-find-outline</v-icon>
                          </v-btn>

                          <v-btn
                            v-if="isEditting"
                            text
                            icon
                            rounded
                            color="error"
                            @click="deleteFile(index)"
                          >
                            <v-icon>mdi-file-cancel-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item v-if="user.parentType === 'Company::Central'" key="5" class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12 md6>
                  <label> Estado actual </label>
                  <p> {{ $t(`bags.state.${object.state}`) }} </p>
                </v-flex>
                <v-flex xs12 md6>
                  <v-select
                    v-model="object.newState"
                    :disabled="!isEditting"
                    :items="states"
                    :label="$t(`${objectName.multiple}.state.newTitle`)"
                    item-text="name"
                    item-value="value"
                  />
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item v-if="object.id !== undefined && ifCompanyCentral()" key="6" class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12>
                  
                </v-flex>
                <v-flex xs12>
                  <v-data-table
                    :headers="historicalHeaders"
                    :items="object.historicalBags"
                    :search="historicalSearch"
                    :items-per-page="-1"
                    :footer-props="{
                      'next-icon': 'mdi-chevron-right',
                      'prev-icon': 'mdi-chevron-left'
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ date(item.historicalDate) }} <br> {{ time(item.historicalDate) }}</td>  
                        <td>{{ item.messageId }}</td>
                        <td>{{ item.eventName }}</td>
                        <td>{{ item.sensor }}</td>
                        <td>{{ item.vbats }}</td>
                        <td>{{ item.temps }}</td>
                        <td>{{ item.vbatg }}</td>
                        <td>{{ item.sensorLora }}</td>

                        <td>{{ item.loraNode }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn v-if="user.parentType == 'Company::Central' || object.status == 'by_assigned'" color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
      id="ruleModal"
      v-model="ruleModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ ruleTitle }}</h5></v-card-title>
        <v-card-text style="flex: 1;" class="pa-0">
          <v-tabs 
            background-color="primary"
            class="text--white"
            slider-color="primary"
            grow
            dark
          >
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.basicInformation`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.message`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.messageVoice`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.deliveryMethodsLabel`).toUpperCase() }}</v-tab>
            <v-tab-item class="px-5">
              <v-layout row wrap>
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-text-field
                    v-model="ruleObject.name"
                    :label="$t('loraNodes.ruleNotifications.name')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>  
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.categoryAlertId"
                    :items="categoryAlertsBluetoothSensor"
                    :label="$t('loraNodes.ruleNotifications.types.title')"
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.priorityId"
                    :items="priorities"
                    :label="$t('loraNodes.ruleNotifications.priority')"
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.calculation.type"
                    :items="[
                      { text: $t('loraNodes.ruleNotifications.calculation.range'), value: 'RANGE' },
                      { text: $t('loraNodes.ruleNotifications.calculation.mask'), value: 'MASK' }
                    ]"
                    :label="$t('loraNodes.ruleNotifications.calculation.title')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-if="ruleObject.calculation.type === 'RANGE'" row wrap>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="ruleObject.calculation.operatorMinimum"
                    :items="['>', '>=', '<', '<=']"
                    :label="$t('loraNodes.ruleNotifications.calculation.operatorMinimum')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="ruleObject.calculation.operatorMaximum"
                    :items="['>', '>=', '<', '<=']"
                    :label="$t('loraNodes.ruleNotifications.calculation.operatorMaximum')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="ruleObject.calculation.valueMinimum"
                    :label="$t('loraNodes.ruleNotifications.calculation.valueMinimum')"
                    :disabled="!isEditting"
                    type="number"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="ruleObject.calculation.valueMaximum"
                    :label="$t('loraNodes.ruleNotifications.calculation.valueMaximum')"
                    :disabled="!isEditting"
                    type="number"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-else row wrap>
                <v-flex class="pl-2 pr-2" xs12>
                  <v-text-field
                    v-model="ruleObject.calculation.mask"
                    :label="$t('loraNodes.ruleNotifications.calculation.mask')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12 pa-1>
                  <v-textarea
                    v-model="ruleObject.message"
                    :label="$t(`${objectName.multiple}.message`)"
                    :disabled="!isEditting || user.parentType != 'Company::Central' "
                    :color="inputColor"
                    :error="objectErrors.message !== undefined"
                    :error-messages="parseErrors(objectErrors.message)"
                  />
                </v-flex>

                <v-flex xs12>
                  <h5>
                    {{ $t(`${objectName.multiple}.variables`) }}
                  </h5>
                  <v-data-table
                    :headers="messageHeaders"
                    :items="messageValues"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>                 
              </v-layout>
            </v-tab-item>
            <v-tab-item class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12 pa-1>
                  <v-textarea
                    v-model="ruleObject.messageVoice"
                    :label="$t(`${objectName.multiple}.messageVoice`)"
                    :disabled="!isEditting|| user.parentType != 'Company::Central' "
                    :color="inputColor"
                    :error="objectErrors.messageVoice !== undefined"
                    :error-messages="parseErrors(objectErrors.messageVoice)"
                  />
                </v-flex>

                <v-flex xs12>
                  <h5>
                    {{ $t(`${objectName.multiple}.variables`) }}
                  </h5>
                  <v-data-table
                    :headers="messageHeaders"
                    :items="messageValues"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>    
            <v-tab-item class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.email.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.email`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.email.active" xs12 md8>
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.email.destinations"
                        :filter="customFilter"
                        :items="availableSelectUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :dark="dark"
                        multiple
                        item-value="id"
                        small-chips
                      >
                        <template slot="selection" slot-scope="{ item }">
                          <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                        </template>

                        <template slot="item" slot-scope="{ item }">
                          <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                        </template>
                      </v-autocomplete>                      
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.telegram.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.telegram`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.telegram.active" xs12 md8>
                      <v-text-field
                        v-model="ruleObject.deliveryMethods.telegram.chatId"
                        :label="$t(`helpers.chatId`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                      />                        
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.sendRequest.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.sendRequest`)"
                      />
                    </v-flex>
                    <v-flex v-if="ruleObject.deliveryMethods.sendRequest.active" xs12 sm4 pa-2>
                      <v-select
                        v-model="ruleObject.deliveryMethods.sendRequest.type"
                        :items="['GET', 'POST']"
                        :disabled="!isEditting"
                        :label="$t(`helpers.requestType`)"
                        :color="inputColor"
                        :dark="dark"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.sendRequest.active" xs12 sm4 pa-2>
                      <v-text-field
                        v-model="ruleObject.deliveryMethods.sendRequest.url"
                        :label="$t(`helpers.url`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.twilio.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.twilio`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.twilio.active" xs12 md6>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.twilio.approvedByCentral"
                        :disabled="!isEditting && user.parentType === 'Company::Central'" :label="$t(`helpers.approvedByCentral`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.twilio.destinations"
                        :items="availableSelectUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                    <v-flex v-if="ruleObject.deliveryMethods.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.twilio.callDestinations"
                        :items="availableSelectUsers"
                        :label="$t(`helpers.callDestinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn v-if="ruleStateNew" color="error" @click="discardNewRule">{{ $t('actions.discard') }}</v-btn>

          <v-btn v-else="" color="error" @click="discardRule">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <v-btn color="success" @click="saveRule">{{ $t('actions.save') }}</v-btn>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardRule">{{ $t('actions.discard') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <configuration-table-dialog 
      :dialog="configurationModal" 
      :headers="configurationHeaders"
      :defaultHeaders="defaultHeaders"
      configurationView="bags"
      @update-dialog="(value) => configurationModal = value"
    />
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import UploadButton from 'vuetify-upload-button'
import DualList from 'vuetify-dual-list'
import axios from 'axios'
import RefreshButton from '@/components/utils/RefreshButton.vue'
import ExportButton from '@/components/utils/ExportButton.vue'
import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/bags/add.gql'
import edit from '@/graphql/mutations/bags/edit.gql'
import object from '@/graphql/queries/bags/detail.gql'
import moment from 'moment'
import ConfigurationTableDialog from '../../components/platform/ConfigurationTableDialog.vue'
const objectName = {
  single: 'bag',
  multiple: 'bags',
  object: {
    single: 'Bag',
    multiple: 'Bags'
  }
}

export default {

  components: {
    AButton,
    DualList,
    UploadButton,
    ConfigurationTableDialog,
    RefreshButton,
    ExportButton
  },

  data () {
    return {
      objectName,
      filtered: false,
      filteredData: [],
      searchIds: [],
      configurationModal: false,
      keys: { primary: 'name', secondary: 'deviceEui' },
      i18n: this.$i18n.t('duallist3'),
      dense: true,
      drawingManager: null,
      loraSensorsBag: [],
      viewType: 'bags',
      companyLocal: undefined,
      depositoryCompany: undefined,
      fieldBatch: undefined,
      product: undefined,
      cycle: undefined,
      sensorizationType: undefined,
      realWeight: undefined,
      contractNumber: undefined,
      contractNumberComm: undefined,
      kitFilter: undefined,
      state: undefined,
      alertsConfigured: undefined,
      ruleObject: {
        calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: [],
            approvedByCentral: false
          }
        }
      },
      ruleModal: false,
      ruleIndex: null,
      ruleStateNew: false,
      fieldGeofence: null,
      pagination: { rowsPerPage: 20 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      geofence: null,
      drawer: null,
      object: {},
      kit: {},
      objectErrors: {},
      search: '',
      historicalSearch: '',
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.reference`),
          align: 'left',
          sortable: true,
          value: 'reference'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.localCompany`),
          align: 'left',
          sortable: true,
          value: 'localCompany'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.depositoryCompany`),
          align: 'left',
          sortable: true,
          value: 'depositoryCompany'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.fieldBatch`),
          align: 'left',
          sortable: true,
          value: 'fieldBatchName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.productName`),
          align: 'left',
          sortable: true,
          value: 'productName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.cycleName`),
          align: 'left',
          sortable: true,
          value: 'cycleName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.sensorizationType`),
          align: 'left',
          sortable: true,
          value: 'sensorizationType'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.realWeight`),
          align: 'left',
          sortable: true,
          value: 'realWeight'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.contractNumber`),
          align: 'left',
          sortable: true,
          value: 'contractNumber'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.contractNumberComm`),
          align: 'left',
          sortable: true,
          value: 'contractNumberComm'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.kitName`),
          align: 'left',
          sortable: true,
          value: 'kitName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.stateLabel`),
          align: 'left',
          sortable: true,
          value: 'state'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.createdAt`),
          align: 'left',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagConfigured.label`),
          align: 'left',
          sortable: true,
          value: 'bagConfigured'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ],
      configurationHeaders: [
        {
          text: this.$i18n.t(`${objectName.multiple}.reference`),
          align: 'left',
          sortable: true,
          value: 'reference',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.localCompany`),
          align: 'left',
          sortable: true,
          value: 'localCompany',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.depositoryCompany`),
          align: 'left',
          sortable: true,
          value: 'depositoryCompany',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.fieldBatch`),
          align: 'left',
          sortable: true,
          value: 'fieldBatchName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.productName`),
          align: 'left',
          sortable: true,
          value: 'productName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.cycleName`),
          align: 'left',
          sortable: true,
          value: 'cycleName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.sensorizationType`),
          align: 'left',
          sortable: true,
          value: 'sensorizationType',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.realWeight`),
          align: 'left',
          sortable: true,
          value: 'realWeight',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.contractNumber`),
          align: 'left',
          sortable: true,
          value: 'contractNumber',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.contractNumberComm`),
          align: 'left',
          sortable: true,
          value: 'contractNumberComm',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.kitName`),
          align: 'left',
          sortable: true,
          value: 'kitName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.stateLabel`),
          align: 'left',
          sortable: true,
          value: 'state',
          format: (value) => { return `<span class="v-chip ${value === 'Activo' ? 'success' : 'warning'} v-size--small">
  <span class="v-chip__content">
    <strong>${value}</strong>
  </span>
</span>`  }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.createdAt`),
          align: 'left',
          sortable: true,
          value: 'createdAt',
          format: (value) => { return `${this.$date(value)} ${this.$time(value)}` }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagConfigured.label`),
          align: 'left',
          sortable: true,
          value: 'bagConfigured',
          format: (value) => { return value }
        },
      ],
      defaultHeaders: [
        {
          text: this.$i18n.t(`${objectName.multiple}.reference`),
          align: 'left',
          sortable: true,
          value: 'reference',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.localCompany`),
          align: 'left',
          sortable: true,
          value: 'localCompany',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.depositoryCompany`),
          align: 'left',
          sortable: true,
          value: 'depositoryCompany',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.fieldBatch`),
          align: 'left',
          sortable: true,
          value: 'fieldBatchName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.productName`),
          align: 'left',
          sortable: true,
          value: 'productName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.cycleName`),
          align: 'left',
          sortable: true,
          value: 'cycleName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.sensorizationType`),
          align: 'left',
          sortable: true,
          value: 'sensorizationType',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.realWeight`),
          align: 'left',
          sortable: true,
          value: 'realWeight',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.contractNumber`),
          align: 'left',
          sortable: true,
          value: 'contractNumber',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.contractNumberComm`),
          align: 'left',
          sortable: true,
          value: 'contractNumberComm',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.kitName`),
          align: 'left',
          sortable: true,
          value: 'kitName',
          format: (value) => { return value }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.stateLabel`),
          align: 'left',
          sortable: true,
          value: 'state',
          format: (value) => { return `<span class="v-chip ${value === 'Activo' ? 'success' : 'warning'} v-size--small">
  <span class="v-chip__content">
    <strong>${value}</strong>
  </span>
</span>`  }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.createdAt`),
          align: 'left',
          sortable: true,
          value: 'createdAt',
          format: (value) => { return `${this.$date(value)} ${this.$time(value)}` }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagConfigured.label`),
          align: 'left',
          sortable: true,
          value: 'bagConfigured',
          format: (value) => { return value }
        },
      ],
      historicalHeaders: [
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.historicalDate`),
          align: 'left',
          sortable: true,
          value: 'historicalDate'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.messageId`),
          align: 'left',
          sortable: true,
          value: 'messageId'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.event`),
          align: 'left',
          sortable: true,
          value: 'eventName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.sensor`),
          align: 'left',
          sortable: true,
          value: 'sensor'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.vbats`),
          align: 'left',
          sortable: true,
          value: 'vbats'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.temps`),
          align: 'left',
          sortable: true,
          value: 'temps'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.vbatg`),
          align: 'left',
          sortable: true,
          value: 'vbatg'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.sensorLora`),
          align: 'left',
          sortable: true,
          value: 'sensorLora'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.historical.loraNode`),
          align: 'left',
          sortable: true,
          value: 'loraNode'
        }
      ]
    }
  },
  watch: {
    user (newValue) {
      console.log(newValue)
    }
  },
  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'products', 'cycles', 'fieldBatches', 'companyDepositories', 'companyLocals', 'loraSensors', 'priorities', 'usersSelect', 'kits']),
    // ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'unlinkedLoraSensors', 'categoryAlertsBluetoothSensor', 'loraSensorsFree', 'freeKits']),
    ...mapGetters([ 'unclosedBags' ]),
    isMobile () {
      return this.width.full < 600
    },
    alertsConfiguredOptions () {
      return [
        {
          text: this.$i18n.t('helpers.actionsOptions.yes'),
          value: "Configurado"
        },
        {
          text: this.$i18n.t('helpers.actionsOptions.no'),
          value: "No configurado"
        }
      ]
    },
    userConfiguration () {
      const basicConfiguration = this.user.viewsConfiguration['bags'] || this.defaultHeaders
      // For in basicConfiguration to get the value and get the format from configurationHeaders
      const configuration = []
      for (var i in basicConfiguration) {
        const index = this.configurationHeaders.findIndex((item) => { return item.value === basicConfiguration[i].value })
        if (index !== -1) {
          configuration.push(Object.assign({}, this.configurationHeaders[index]))
        }
      }
      return configuration
    },
    renderHeaders () {
      return [
        ...this.userConfiguration,
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    renderHeadersWeightIndex() {
      const index = this.renderHeaders.findIndex((item) => { return item.value === 'realWeight' })
      return index
    },
    data () {
      // Preapre a variable called data with the unclosedBags but without having a reference to the store
      const data = []
      for (var i in this.unclosedBags) {
        data.push(Object.assign({}, this.unclosedBags[i]))
      }
      for (var i in data) {
        data[i].state = this.$t(`bags.state.${data[i].state}`)
        data[i].bagConfigured = this.$t(`bags.bagConfigured.${data[i].bagConfigured}`)
      }
      return data
    },
    generalOptions () {
      return [
        {
          text: this.$i18n.t(`actions.general.yes`),
          value: true
        },
        {
          text: this.$i18n.t(`actions.general.no`),
          value: false
        }
      ]
    },
    loraSensorsFreeBag: {

      get () {
        return this.loraSensorsFree.concat(this.loraSensorsBag)
      },

      set (newValue) {
        this.loraSensorsBag = this.loraSensors.filter((item, index, arr) => { return newValue.includes(item.id) })
      }
    },

    states () {
      return [
        {
          name: this.$i18n.t('bags.state.active'),
          value: 'active'
        },
        {
          name: this.$i18n.t('bags.state.test'),
          value: 'test'
        },
        {
          name: this.$i18n.t('bags.state.on_load'),
          value: 'on_load'
        }
      ]
    },
    sensorizationTypes () {
      return [
        {
          text: this.$i18n.t('sensorization.sp'),
          value: 'SP'
        },
        {
          text: this.$i18n.t('sensorization.st'),
          value: 'ST'
        }
      ]
    },
    messageHeaders () {
      return [
        {
          text: this.$i18n.t(`variables.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`variables.variable`),
          align: 'left',
          sortable: true,
          value: 'variable'
        }
      ]
    },
    messageValues () {
      return [
        {
          name: this.$i18n.t(`variables.nodeName`),
          value: '{nodeName}'
        },
        {
          name: this.$i18n.t(`variables.nodeSubscriber`),
          value: '{nodeSubscriber}'
        },
        {
          name: this.$i18n.t(`variables.localMonitor`),
          value: '{localMonitor}'
        },
        {
          name: this.$i18n.t(`variables.depositoryCompany`),
          value: '{depositoryCompany}'
        },
        {
          name: this.$i18n.t(`variables.kitNumber`),
          value: '{kitNumber}'
        },
        {
          name: this.$i18n.t(`variables.sensorIdentifier`),
          value: '{sensorIdentifier}'
        },
        {
          name: this.$i18n.t(`variables.sensorDevice`),
          value: '{sensorDevice}'
        },
        {
          name: this.$i18n.t(`variables.bagReference`),
          value: '{bagReference}'
        },
        {
          name: this.$i18n.t(`variables.bagNomenclature`),
          value: '{bagNomenclature}'
        },
        {
          name: this.$i18n.t(`variables.bagCycle`),
          value: '{bagCycle}'
        },
        {
          name: this.$i18n.t(`variables.bagProduct`),
          value: '{bagProduct}'
        },
        {
          name: this.$i18n.t(`variables.bagFieldBatch`),
          value: '{bagFieldBatch}'
        },
        {
          name: this.$i18n.t(`variables.messageTime`),
          value: '{messageTime}'
        },
        {
          name: this.$i18n.t(`variables.messageTemps`),
          value: '{messageTemps}'
        },
        {
          name: this.$i18n.t(`variables.messageVbats`),
          value: '{messageVbats}'
        },
        {
          name: this.$i18n.t(`variables.messageVbatg`),
          value: '{messageVbatg}'
        },
        {
          name: this.$i18n.t(`variables.messageEvent`),
          value: '{messageEvent}'
        }
      ]
    },
    rulesHeaders () {
      return [
        {
          text: this.$i18n.t('bags.ruleNotifications.name'),
          value: 'name',
          sortable: false
        },
        // {
        //   text: this.$i18n.t('bags.ruleNotifications.types.title'),
        //   value: 'types',
        //   sortable: false
        // },
        {
          text: this.$i18n.t('bags.ruleNotifications.calculation.title'),
          value: 'calculation',
          sortable: false
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ]
    },

    mediaHeaders () {
      return [
        {
          text: this.$i18n.t('bags.media.name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ]
    },

    availableSelectUsers () {
      if (this.object.fieldBatchId === null) {
        return []
      }
      const fieldBatch = this.fieldBatches.find((field) => field.id === this.object.fieldBatchId)
      return this.usersSelect.filter((user) => (user.parentId === fieldBatch.companyDepositoryId && user.parentType === 'Company::Depository') || 
      (user.parentId === fieldBatch.companyLocalId && user.parentType === 'Company::Local') || (user.parentType === 'Company::Central'))
    },

    defaultObject () {
      return {
        id: undefined,
        reference: '',
        cycleId: null,
        productId: null,
        fieldBatchId: null,
        geofence: [],
        media: [],
        estimatedWeight: 0,
        realWeight: 0,
        longitude: 0,
        distanceFromTheNode: 0,
        kitId: null,
        localContactName: '',
        localContactPhone: '',
        loraSensors: [],
        ruleNotifications: [],
        state: 'by_assigned',
        financialAdvances: false,
        weightGaranty: false,
        contractNumber: '',
        contractNumberComm: '',
        observation: '',
        companyLocal: undefined,
        companyDepository: undefined,
        fieldBatch: undefined,
        product: undefined,
        cycle: undefined,
        sensorizationType: undefined,
        realWeight: undefined,
        contractNumber: undefined,
        contractNumberComm: undefined,
        kit: undefined,
        state: undefined,
        alertsConfigured: undefined
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.reference })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.reference })
        }
      }
    },

    ruleTitle () {
      return this.$i18n.t(`${objectName.multiple}.ruleNotifications.title`, { name: this.ruleObject.name })
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#bagsCard')
    const search = document.getElementById('bagsSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    cleanFilters () {
      this.companyLocal = undefined
      this.companyDepository = undefined
      this.fieldBatch = undefined
      this.product = undefined
      this.cycle = undefined
      this.sensorizationType = undefined
      this.realWeight = undefined
      this.contractNumber = undefined
      this.contractNumberComm = undefined
      this.kitFilter = undefined
      this.state = undefined
      this.alertsConfigured = undefined
      this.filtered = false
      this.viewType = 'bags'
    },
    filterData () {
      // Validate at least one filter is not null
      if (this.companyLocal === undefined && this.depositoryCompany === undefined && this.fieldBatch === undefined && this.product === undefined && this.cycle === undefined && this.sensorizationType === undefined && this.realWeight === undefined && this.contractNumber === undefined && this.contractNumberComm === undefined && this.kitFilter === undefined && this.state === undefined && this.alertsConfigured === undefined) {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('helpers.noFilterSelected')
        })
        return
      }
      this.filteredData = []
      console.log(this.data)
      for (const bag of this.data) {
        let valid = true
        if (this.companyLocal !== undefined) {
          valid = bag.localCompany === this.companyLocal
        }
        if (this.depositoryCompany !== undefined) {
          valid = valid && bag.depositoryCompany === this.depositoryCompany
        }
        if (this.fieldBatch !== undefined) {
          valid = valid && bag.fieldBatchName === this.fieldBatch
        }
        if (this.product !== undefined) {
          valid = valid && bag.productName === this.product
        }
        if (this.cycle !== undefined) {
          valid = valid && bag.cycleName === this.cycle
        }
        if (this.sensorizationType !== undefined) {
          valid = valid && bag.sensorizationType === this.sensorizationType
        }
        if (this.realWeight !== undefined) {
          valid = valid && bag.realWeight === this.realWeight
        }
        if (this.contractNumber !== undefined) {
          valid = valid && bag.contractNumber === this.contractNumber
        }
        if (this.contractNumberComm !== undefined) {
          valid = valid && bag.contractNumberComm === this.contractNumberComm
        }
        if (this.kitFilter !== undefined) {
          valid = valid && bag.kitName === this.kitFilter
        }
        if (this.state !== undefined) {
          valid = valid && bag.state === this.state
        }
        if (this.alertsConfigured !== undefined) {
          valid = valid && bag.bagConfigured === this.alertsConfigured
        }
        if (valid) {
          this.filteredData.push(bag)
        }
      }
      this.filtered = true
      this.viewType = 'bags'
    },
    changeType (type) {
      this.viewType = type
    },
    showConfiguration () {
      this.configurationModal = true
    },
    restartIds () {
      this.searchIds = []
    },
    ifCompanyCentral () {
      return this.user.parentType === 'Company::Central'
    },
    handleScroll (event) {
      const elem = document.getElementById('bagsCard')
      const search = document.getElementById('bagsSearchFixed').childNodes[0]
      const searchCard = document.getElementById('bagsSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },
    
    isEdittable () {
      return this.isEditting && (this.object.state !== 'active' && this.object.state !== 'on_load')
    },

    selectableKits () {
      const kit = this.kits.find((item) => item.id === this.object.kitId)
      const kits = [{
        id: null,
        reference: 'Ningun kit'
      },
      ...this.freeKits
      ]

      if (kit !== null && kit !== undefined) {
        kits.push(kit)
      }
      return kits
    },
    setKit () {
      if (this.object.kitId === null) {
        this.kit = {
          id: null,
          reference: 'Ningun kit'
        }
      } else {
        this.kit = this.kits.find((item) => item.id === this.object.kitId)
      }
    },
    date (date) {
      return moment.unix(date).format('YYYY-MM-DD')
    },

    time (time) {
      return moment.unix(time).format('HH:mm:ss')
    },

    customFilter (item, query, arr) {
      const name = item.username.toLowerCase() + '' + this.$i18n.t(`users.${item.parentType.split('::')[1]}`).toLowerCase()
      return name.includes(query)
    },
    emailDestinations () {
      return this.ruleObject.email.destinationsCentral
    },
    deleteFile (i) {
      this.object.media.splice(i, 1)

      const media = this.object.media
      this.object.media = []
      this.object.media = Object.values(Object.assign({}, media))
    },

    uploadFile (file) {
      if (file === undefined) {
        return
      }
      
      const formData = new FormData()

      formData.append('files[0]', file)

      axios.post('/api/files/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then((response) => {
        this.object.media.push({
          name: response.data.files[0],
          uri: `${window.location.protocol}//${window.location.host}/${response.data.files[0]}`
        })
      }).catch((error) => {
        
      }).finally(() => {
        this.$refs.uploadBtn.clear()
      })
    },

    addRule () {
      this.object.ruleNotifications.push({
        id: null,
        name: '',
        priorityId: 0,
        categoryAlertId: 0,
        message: '',
        messageVoice: '',
        calculation: {
          type: 'RANGE',
          operatorMinimum: '>=',
          operatorMaximum: '<=',
          valueMinimum: 0,
          valueMaximum: 0,
          mask: '%val%'
        },
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: [],
            approvedByCentral: false
          }
        }
      })
      this.ruleObject = Object.assign({}, {
        id: null,
        name: '',
        priorityId: 0,
        categoryAlertId: 0,
        message: '',
        messageVoice: '',
        calculation: {
          type: 'RANGE',
          operatorMinimum: '>=',
          operatorMaximum: '<=',
          valueMinimum: 0,
          valueMaximum: 0,
          mask: '%val%'
        },
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: [],
            approvedByCentral: false
          }
        }
      })
      this.ruleModal = true
      this.ruleStateNew = true
      this.ruleIndex = this.object.ruleNotifications.length - 1
    },

    discardNewRule () {
      this.ruleModal = false
      this.ruleObject = { 
        calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: [],
            approvedByCentral: false
          }
        }
      }
      this.object.ruleNotifications.pop()
      this.ruleIndex = null
    },

    editRule (i) {
      this.ruleIndex = i
      this.ruleObject = Object.assign({}, this.object.ruleNotifications[i])
      this.ruleModal = true
    },

    saveRule () {
      if (this.ruleIndex !== null) {
        this.object.ruleNotifications[this.ruleIndex] = Object.assign({}, this.ruleObject)
        this.ruleModal = false
        this.ruleIndex = null

        const rules = this.object.ruleNotifications
        this.object.ruleNotifications = []
        this.object.ruleNotifications = Object.values(Object.assign({}, rules))
      }
    },

    discardRule () {
      this.ruleModal = false
      this.ruleObject = {
        calculation: {}, 
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: [],
            approvedByCentral: false
          }
        }
      }
      this.ruleIndex = null
    },

    deleteRule (i) {
      this.object.ruleNotifications.splice(i, 1)
      this.discardRule()
    },

    attachItems (items) {
      this.object.loraSensors = items.map((item, index, arr) => { return item.id })
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      setTimeout(this.initMapEdit, 1000)
      this.isEditting = true
    },

    reDrawField () {
      if (this.fieldGeofence !== null) {
        this.fieldGeofence.setMap(null)
      }
      this.drawField()
    },

    cleanGeofences () {
      this.fieldGeofence.setMap(null)
      this.geofence.setMap(null)
    },

    drawField () {
      const field = this.fieldBatches.find((item) => item.id === this.object.fieldBatchId)
      this.fieldGeofence = new window.google.maps.Polygon({
        paths: field.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
        strokeColor: '#1565c0',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#1565c0',
        fillOpacity: 0.35
      })

      this.fieldGeofence.setMap(this.map)
    },

    initMapEdit () {
      if (this.drawingManager === null) { 
        this.drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon']
          },
          polygonOptions: {
            fillColor: '#823d8f',
            fillOpacity: 0.5,
            strokeColor: '#823d8f',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: true,
            zIndex: 1
          }
        })
        this.drawingManager.setMap(this.map)
        window.google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.attachGeofence)
      } else {
        this.drawingManager.setMap(this.map)

        this.drawingManager.setOptions({
          drawingControl: true
        })
        this.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
      }
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          this.object.createdAtValue = `${this.$date(this.object.createdAt)} ${this.$time(this.object.createdAt)}`
          this.setKit()
          this.loraSensorsFreeBag = this.object.loraSensors

          setTimeout(this.initMapShow, 1000)
          setTimeout(this.drawField, 1000)
          this.formModal = true
          this.isEditting = false
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    initMapShow () {
      const object = this.object

      var bounds = new window.google.maps.LatLngBounds()
      var i
      if (this.object.geofence.length > 0) {
        for (i = 0; i < this.object.geofence.length; i++) {
          const coordinates = new window.google.maps.LatLng(this.object.geofence[i].latitude, this.object.geofence[i].longitude)
          bounds.extend(coordinates)
        }
      } else {
        const field = this.fieldBatches.find((item) => item.id === this.object.fieldBatchId)
        for (i = 0; i < field.geofence.length; i++) {
          const coordinates = new window.google.maps.LatLng(field.geofence[i].latitude, field.geofence[i].longitude)
          bounds.extend(coordinates)
        }
      }
      
      const center = { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() } 

      if (this.map === null) {
        this.map = new window.google.maps.Map(document.getElementById('bagMap'), {
          zoom: 12,
          center: center
        })
      } else {
        this.map.setCenter(center)
        this.map.setZoom(12)
      }

      if (this.geofence !== null) {
        this.geofence.setMap(null)
      }

      this.geofence = new window.google.maps.Polygon({
        paths: object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
        strokeColor: '#46FF33',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#46FF33',
        fillOpacity: 0.35
      })

      this.geofence.setMap(this.map)
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.kit = {}
      this.formModal = false
      this.cleanGeofences()
      if (this.geofence != null) {
        this.geofence.setMap(null)
      }
      this.isEditting = false
      if (this.newShape != null) {
        this.newShape.setMap(null)
      }

      if (this.drawingManager !== null) {
        this.drawingManager.setDrawingMode(null)

        this.drawingManager.setOptions({
          drawingControl: false
        })
      }
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].clear()
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
      this.loraSensorsFreeBag = [] 
      
      setTimeout(this.initMapNew, 1000)
    },

    initMapNew () {
      if (this.map === null) {
        const center = { lat: -32.3671182, lng: -64.4977879 }
        this.map = new window.google.maps.Map(document.getElementById('bagMap'), {
          zoom: 12,
          center: center
        })
      } else {
        this.map.setCenter({ lat: -32.3671182, lng: -64.4977879 })
        this.map.setZoom(12)
      }
      if (this.drawingManager === null) {
        this.drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon']
          },
          polygonOptions: {
            fillColor: '#823d8f',
            fillOpacity: 0.5,
            strokeColor: '#823d8f',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: true,
            zIndex: 1
          }
        })

        this.drawingManager.setMap(this.map)
        window.google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.attachGeofence)
      } else {
        this.drawingManager.setOptions({
          drawingControl: true
        })
        this.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
        this.object.geofence = []
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].clear()
      }
    },

    attachGeofence (e) {
      this.newShape = e.overlay
      this.newShape.setMap(null)
      const drawingManager = this.drawingManager
      this.drawingManager.setDrawingMode(null)

      this.drawingManager.setOptions({
        drawingControl: false
      })
      this.object.geofence = []

      const points = e.overlay.getPath().getArray()
      
      for (const i in points) {
        this.object.geofence.push({
          latitude: points[i].lat(),
          longitude: points[i].lng()
        })
      }
      var bounds = new window.google.maps.LatLngBounds()

      for (let i = 0; i < this.object.geofence.length; i++) {
        const coordinates = new window.google.maps.LatLng(this.object.geofence[i].latitude, this.object.geofence[i].longitude)
        bounds.extend(coordinates)
      }
      const center = { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() } 
      if (this.map === null) {
        this.map = new window.google.maps.Map(document.getElementById('bagMap'), {
          zoom: 12,
          center: center
        })
      } else {
        this.map.setCenter(center)
        this.map.setZoom(12)
      }

      if (this.geofence !== null) {
        this.geofence.setMap(null)
      }

      this.geofence = new window.google.maps.Polygon({
        paths: this.object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
        strokeColor: '#46FF33',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#46FF33',
        fillOpacity: 0.35
      })

      this.geofence.setMap(this.map)
      this.deleteGeofenceButton(this.drawingManager, this.newShape, this.object)
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    deleteGeofenceButton (drawingManager, newShape, object) {
      var centerControlDiv = document.createElement('div')
      // Set CSS for the control border.
      var controlUI = document.createElement('div')
      controlUI.style.backgroundColor = '#fff'
      controlUI.style.border = '2px solid #fff'
      controlUI.style.borderRadius = '3px'
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
      controlUI.style.cursor = 'pointer'
      controlUI.style.marginBottom = '22px'
      controlUI.style.textAlign = 'center'
      controlUI.style.margin = '10px'
      controlUI.title = 'Delete Geofence'
      centerControlDiv.appendChild(controlUI)

      // Set CSS for the control interior.
      var controlText = document.createElement('div')
      controlText.style.color = 'rgb(25,25,25)'
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
      controlText.style.fontSize = '16px'
      controlText.style.lineHeight = '38px'
      controlText.style.paddingLeft = '5px'
      controlText.style.paddingRight = '5px'
      controlText.innerHTML = 'Redibujar Geocerca'
      controlUI.appendChild(controlText)
      const map = this.map
      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener('click', function () {
        newShape.setMap(null)
        drawingManager.setOptions({
          drawingControl: true
        })
        drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
        // object.geofence = []
        map.controls[google.maps.ControlPosition.TOP_RIGHT].clear()
      })
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv)
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      const variables = {
        object: this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename
      delete variables.object.historicalBags

      for (const i in variables.object.geofence) {
        delete variables.object.geofence[i].__typename
      }

      for (const i in variables.object.ruleNotifications) {
        delete variables.object.ruleNotifications[i].__typename

        variables.object.ruleNotifications[i].calculation.valueMinimum = parseFloat(variables.object.ruleNotifications[i].calculation.valueMinimum)
        variables.object.ruleNotifications[i].calculation.valueMaximum = parseFloat(variables.object.ruleNotifications[i].calculation.valueMaximum)

        delete variables.object.ruleNotifications[i].calculation.__typename
      }

      for (const i in variables.object.media) {
        delete variables.object.media[i].__typename
      }

      variables.object.estimatedWeight = parseFloat(variables.object.estimatedWeight)
      variables.object.realWeight = parseFloat(variables.object.realWeight)
      variables.object.longitude = parseFloat(variables.object.longitude)
      variables.object.distanceFromTheNode = parseFloat(variables.object.distanceFromTheNode)
      variables.object = Object.assign({}, variables.object)
      delete variables.object.createdAt
      delete variables.object.createdAtValue
      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = {
            ...result
          }
          this.object.createdAtValue = `${this.$date(this.object.createdAt)} ${this.$time(this.object.createdAt)}`
          this.drawingManager.setMap(null)

          this.geofence = new window.google.maps.Polygon({
            paths: this.object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
            strokeColor: '#46FF33',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#46FF33',
            fillOpacity: 0.35
          })
          
          this.geofence.setMap(this.map)

          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.dispatch('getLoraSensors')
          this.$store.dispatch('getKits')
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false

      this.$store.dispatch('getLoraSensors')
      this.$store.dispatch('getKits')
    },
    bagsFilter (value, query, item) {
       return value != null &&
        query != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1
    }
    
  }
}
</script> 
<style lang="scss">
  
</style> 