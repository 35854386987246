<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="ordersSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="ordersCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>

            <v-spacer />
            <v-text-field
              id="ordersSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="showConfiguration"
                >
                  <v-icon class="yellow-color-text">mdi-cog</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`actions.configuration`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>  
              </template> 

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
            <refresh-button v-if="!isMobile"></refresh-button>
            <export-button :headers="userConfiguration" :items="parsedData" :file-name="'Orders'" :search="search"></export-button>
          </v-toolbar>
          <v-card-text>
            
            <v-data-table
              fixed-header
              height="800"
              :headers="renderHeaders"
              :items="parsedData"
              :search="search"
              :items-per-page="-1"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td v-for="(header, index) in userConfiguration" :key="`${header.value}-${item.id}`">
                      <span :style="index === 0 ? 'font-weight: bold;' : ''" v-html="header.format(item[header.value])"></span>
                    </td>
                    <!-- <td>{{ item.id }}</td>
                    <td>{{ item.orderCategoryName }}</td>
                    <td>{{ item.companyDepositoryName }}</td>
                    <td>{{ item.bagsName }}</td>
                    <td>{{ item.executionDate }}</td>
                    <td>{{ $t(`orders.states.${item.state}`) }}</td> -->
                    <td style="text-align: right; padding: 0;">
                      <v-btn
                        text
                        icon
                        rounded
                        color="info"
                        @click="showItem(item.id)"
                      >
                        <v-icon>mdi-file-find-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="item.state === 'pending'"
                        text
                        icon
                        rounded
                        color="warning"
                        @click="editItem(item.id)"
                      >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
  
                      <v-btn
                        v-if="item.state === 'pending'"
  
                        text
                        icon
                        rounded
                        color="error"
                        @click="deleteItem(item.id)"
                      >
                        <v-icon>mdi-file-cancel-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <!-- <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title> -->
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-1 align-center justify-center>
            <v-flex class="pl-1 pr-1" xs12 :class="object.categoryOrderId === '1' ? 'sm6' : ''">
              <v-select
                v-model="object.categoryOrderId"
                :items="categoryOrders"
                :label="$t(`${objectName.multiple}.categoryOrderId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                item-value="id"
                :error="objectErrors.categoryOrder !== undefined"
                :error-messages="parseErrors(objectErrors.categoryOrder)"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.cycleId"
                :items="cycles"
                :label="$t(`${objectName.multiple}.cycleId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                :error="objectErrors.cycle !== undefined"
                :error-messages="parseErrors(objectErrors.cycle)"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.financialAdvances"
                :items="generalOptions"
                :label="$t(`${objectName.multiple}.financialAdvances`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="text"
                :error="objectErrors.financialAdvances !== undefined"
                :error-messages="parseErrors(objectErrors.financialAdvances)"
                item-value="value"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.weightGaranty"
                :items="generalOptions"
                :label="$t(`${objectName.multiple}.weightGaranty`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="text"
                :error="objectErrors.weightGaranty !== undefined"
                :error-messages="parseErrors(objectErrors.weightGaranty)"
                item-value="value"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12>
              <v-autocomplete
                v-model="object.companyDepositoryId"
                :items="companyDepositoriesData"
                :label="$t(`${objectName.multiple}.companyDepositoryId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.companyDepositoryId !== undefined"
                :error-messages="parseErrors(objectErrors.fieldBatch)"
                item-text="name"
                item-value="id"
                @change="checkCompanyDepositoryId"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.productId"
                :items="products"
                :label="$t(`${objectName.multiple}.productId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.product !== undefined"
                :error-messages="parseErrors(objectErrors.product)"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1' || object.categoryOrderId == '3' || object.categoryOrderId === '4'" class="pl-1 pr-1" xs12 :class="object.categoryOrderId == '3' || object.categoryOrderId == '4' ? 'sm12' : 'sm6'">
              <v-select
                v-model="object.fieldBatchId"
                :items="customreFieldBatches"
                :label="$t(`${objectName.multiple}.fieldBatchId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.fieldBatch !== undefined"
                :error-messages="parseErrors(objectErrors.fieldBatch)"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-select
                v-model="object.installerId"
                :items="installersFiltered"
                :label="$t(`${objectName.multiple}.installerId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="object.executionDate"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="object.executionDate"
                    :label="$t(`${objectName.multiple}.executionDate`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.executionDate !== undefined"
                    :error-messages="parseErrors(objectErrors.executionDate)"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="object.executionDate" scrollable>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog.save(object.executionDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-text-field
                v-model="object.bagsQuantity"
                type="number"
                :label="$t(`${objectName.multiple}.bagsQuantity`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.bagsQuantity !== undefined"
                :error-messages="parseErrors(objectErrors.bagsQuantity)"
                min="0"
                @change="adjustBags"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '1'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.bagsIds"
                :items="parsedCustomerBags"
                :multiple="true"
                :label="$t(`${objectName.multiple}.bagsIds`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="reference"
                item-value="id"
                @change="validateIfMoreThanOne"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '4'" class="pl-1 pr-1" xs12>
              <v-text-field
                v-model="bagKitName"
                :multiple="true"
                :label="$t(`${objectName.multiple}.kitName`)" 
                :disabled="true"
                :color="inputColor"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '4'" class="pl-1 pr-1" xs12>
              <!-- Show the list of sensors  -->
              <template v-for="(sensor, index) in bagKit.loraSensors">
                <!-- List with Identifier and sensor name -->
                <v-layout row wrap>
                  <v-flex xs12 sm4 class="px-2">
                    <v-text-field
                      v-model="sensor.identifier"
                      :label="$t(`${objectName.multiple}.sensorsName`)" 
                      :disabled="true"
                      :color="inputColor"
                    />
                  </v-flex>
                  <v-flex xs12 sm4 class="px-2">
                    <v-text-field
                      v-model="sensor.name"
                      :label="$t(`${objectName.multiple}.sensorsIdentifier`)" 
                      :disabled="true"
                      :color="inputColor"
                    />
                  </v-flex>
                  <v-flex xs12 sm2 class="px-2">
                    <!-- Checkbox -->
                    <v-checkbox
                      v-model="sensor.active"
                      :label="$t(`${objectName.multiple}.sensorActive`)"
                      :disabled="!isEditting"
                    />
                  </v-flex>
                  <v-flex xs12 sm2 class="px-2">
                    <!-- New sensor Name -->
                    <v-text-field
                      v-model="sensor.newName"
                      :label="$t(`${objectName.multiple}.newsensorsIdentifier`)" 
                      :disabled="!isEditting || !sensor.active"
                      :color="inputColor"
                      :error="haveSensorsErrors(sensor)"
                      :error-messages="parseSensorErrors(sensor)"
                    />
                  </v-flex>
                </v-layout>
              </template>
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '2' && object.categoryOrderId !== '4'" class="pl-1 pr-1" xs12>
              <v-autocomplete
                v-model="object.kitsIds"
                :items="selectableKits()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.kitsIds`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="helper"
                item-value="id"
                @change="validateIfMoreThanOneKits"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '4'" class="pl-1 pr-1" xs12>
              <!-- <v-select
                v-model="object.sensors"
                :items="selectableLoraSensors()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.sensors`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="deviceEui"
                item-value="id"
              /> -->
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '3' || object.categoryOrderId === '4'" class="pl-1 pr-1" xs12>
              <v-text-field
                v-model="object.comments.comment"
                :label="$t(`${objectName.multiple}.comments`)" 
                :disabled="!isEditting"
                :color="inputColor"
              />
            </v-flex>
            
            <!-- <v-flex v-if="object.categoryOrderId !== '2'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.bluetoothSensors"
                :items="selectableBtSensors()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.bluetoothSensors`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="sensorId"
                item-value="id"
              />
            </v-flex> -->
            <v-flex v-if="object.categoryOrderId === '1'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.sensorizationType"
                :items="sensorizationTypes"
                :label="$t(`${objectName.multiple}.sensorizationType`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="text"
                item-value="value"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1' && object.sensorizationType !== ''" class="pl-1 pr-1" xs12 :class="object.sensorizationType === 'ST' ? 'sm6' : ''">
              <v-text-field
                v-model="object.contractNumberComm"
                type="number"
                :label="$t(`${objectName.multiple}.contractNumberComm`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.contractNumberComm !== undefined"
                :error-messages="parseErrors(objectErrors.contractNumberComm)"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1' && object.sensorizationType === 'ST'" class="pl-1 pr-1" xs12 sm6>
              <v-text-field
                v-model="object.contractNumber"
                type="number"
                :label="$t(`${objectName.multiple}.contractNumber`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.contractNumber !== undefined"
                :error-messages="parseErrors(objectErrors.contractNumber)"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '1'" xs12>
              <v-data-table
                :headers="bagHeaders"
                :items="object.bags"
                :search="search"
                :items-per-page="-1"
                :footer-props="{
                  'next-icon': 'mdi-chevron-right',
                  'prev-icon': 'mdi-chevron-left'
                }"
              >
                <template v-slot:body="{ items }">
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <v-text-field
                        v-model="item.reference"
                        :label="$t(`bags.reference`)" 
                        :disabled="!isEditting"
                        :color="inputColor"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.estimatedWeight"
                        :label="$t(`bags.estimatedWeight`)"
                        type="number"
                        min="0" 
                        :disabled="!isEditting"
                        :color="inputColor"
                      />
                    </td>                
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '1'" xs12>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-switch 
                    v-model="object.configuration.email.active"
                    :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.email`)"
                  />
                </v-flex>

                <v-flex v-if="object.configuration.email.active" xs12 md8>
                  <v-autocomplete
                    v-model="object.configuration.email.destinations"
                    :filter="customFilter"
                    :items="availableSelectUsers"
                    :label="$t(`helpers.destinations`)"
                    :disabled="!isEditting"
                    :dark="dark"
                    multiple
                    item-value="id"
                    small-chips
                  >
                    <template slot="selection" slot-scope="{ item }">
                      <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                    </template>

                    <template slot="item" slot-scope="{ item }">
                      <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                    </template>
                  </v-autocomplete>                      
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-switch 
                        v-model="object.configuration.twilio.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.twilio`)"
                      />
                    </v-flex>

                    <v-flex v-if="object.configuration.twilio.active" xs12 md6>
                      <v-switch 
                        v-model="object.configuration.twilio.approvedByCentral"
                        :disabled="!isEditting && user.parentType === 'Company::Central'" :label="$t(`helpers.approvedByCentral`)"
                      />
                    </v-flex>

                    <v-flex v-if="object.configuration.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="object.configuration.twilio.destinations"
                        :items="availableSelectUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                    <v-flex v-if="object.configuration.twilio.active" xs12 md6>
                      <v-autocomplete
                        v-model="object.configuration.twilio.callDestinations"
                        :items="availableSelectUsers"
                        :label="$t(`helpers.callDestinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                  </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" :disabledButton="checkSensors" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" :disabledButton="checkSensors" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          <v-btn color="primary" @click="copyOrder()">{{ $t('actions.copyOrder', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
          <v-spacer />
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <configuration-table-dialog 
      :dialog="configurationModal" 
      :headers="configurationHeaders"
      :defaultHeaders="defaultHeaders"
      configurationView="orders"
      @update-dialog="(value) => configurationModal = value"
    />
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import ConfigurationTableDialog from '../../components/platform/ConfigurationTableDialog.vue'
import RefreshButton from '@/components/utils/RefreshButton.vue'
import ExportButton from '@/components/utils/ExportButton.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/orders/add.gql'
import edit from '@/graphql/mutations/orders/edit.gql'
import object from '@/graphql/queries/orders/detail.gql'
import moment from 'moment'

const objectName = {
  single: 'order',
  multiple: 'orders',
  object: {
    single: 'Order',
    multiple: 'Orders'
  }
}

export default {
  components: {
    AButton,
    ConfigurationTableDialog,
    RefreshButton,
    ExportButton
  },

  data () {
    return {
      objectName,
      configurationModal: false,
      modal: false,
      pagination: { rowsPerPage: 20 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      object: {},
      objectErrors: {},
      search: '',
      usableKits: [],
      usableBtSensors: [],
      usableLoraSensors: [],
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.id`),
          align: 'left',
          sortable: true,
          value: 'id'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.categoryOrderId`),
          align: 'left',
          sortable: true,
          value: 'categoryOrderName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyDepositoryId`),
          align: 'left',
          sortable: true,
          value: 'companyDepositoryName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagsIds`),
          align: 'left',
          sortable: true,
          value: 'bagsName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.executionDate`),
          align: 'left',
          sortable: true,
          value: 'executionDate'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.state`),
          align: 'left',
          sortable: true,
          value: 'stateName'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ],
      bagHeaders: [
        {
          text: this.$i18n.t(`bags.reference`)
        },
        {
          text: this.$i18n.t(`bags.estimatedWeight`)
        }
      ],
      bagKitName: undefined,
      bagKit: {}
    }
  },

  computed: {
    ...mapState(['user', 'bags', 'width', 'dark', 'language', 'categoryOrders', 'cycles', 'products', 'fieldBatches', 'installers', 'loraSensors', 'btSensors', 'kits', 'companyDepositories', 'companyLocals', 'usersSelect']),
    // ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'loraSensorsBtSensors', 'freeKitsLabeled', 'ownedKitsLabeled', 'freeBtSensors', 'freeLoraSensors', 'validFields', 'unclosedBags']),
    ...mapGetters({
      data: 'noFinishedOrders'
    }),
    isMobile () {
      return this.width.full < 600
    },
    checkSensors () {
      // if the category order is 4 check the sensors
      if (this.object.categoryOrderId === '4') {
        if (this.bagKit === undefined) {
          return true
        }
        if (this.bagKit.loraSensors === undefined) {
          return true
        }
        // Validate bagKit.loraSensors has all newName in case active is true, and also have no errors
        const loraSensors = this.bagKit.loraSensors.filter((sensor) => sensor.active)
        const loraSensorsErrors = this.loraSensors.filter((sensor) => this.haveSensorsErrors(sensor))
        if (loraSensorsErrors.length > 0) {
          return true
        }
      }
      return false
    },
    defaultHeaders () {
      return [
      {
          text: this.$i18n.t(`${objectName.multiple}.id`),
          align: 'left',
          sortable: true,
          value: 'id',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.categoryOrderId`),
          align: 'left',
          sortable: true,
          value: 'categoryOrderName',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyDepositoryId`),
          align: 'left',
          sortable: true,
          value: 'companyDepositoryName',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagsIds`),
          align: 'left',
          sortable: true,
          value: 'bagsName',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.executionDate`),
          align: 'left',
          sortable: true,
          value: 'executionDate',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.state`),
          align: 'left',
          sortable: true,
          value: 'stateName',
          format: (value) => value
        },
      ]
    },
    configurationHeaders () {
      return [
      {
          text: this.$i18n.t(`${objectName.multiple}.id`),
          align: 'left',
          sortable: true,
          value: 'id',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.categoryOrderId`),
          align: 'left',
          sortable: true,
          value: 'categoryOrderName',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyDepositoryId`),
          align: 'left',
          sortable: true,
          value: 'companyDepositoryName',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagsIds`),
          align: 'left',
          sortable: true,
          value: 'bagsName',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.executionDate`),
          align: 'left',
          sortable: true,
          value: 'executionDate',
          format: (value) => value
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.state`),
          align: 'left',
          sortable: true,
          value: 'stateName',
          format: (value) => value
        },
      ]
    },
    renderHeaders () {
      return [
        ...this.userConfiguration,
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    },

    userConfiguration () {
      const basicConfiguration = this.user.viewsConfiguration['orders'] || this.defaultHeaders
      // For in basicConfiguration to get the value and get the format from configurationHeaders
      const configuration = []
      for (var i in basicConfiguration) {
        const index = this.configurationHeaders.findIndex((item) => { return item.value === basicConfiguration[i].value && item.text === basicConfiguration[i].text })
        if (index !== -1) {
          configuration.push(Object.assign({}, this.configurationHeaders[index]))
        }
      }
      return configuration
    },
    installersFiltered () {
      const companyDepository = this.companyDepositories.find((companyDepository) => companyDepository.id === this.object.companyDepositoryId)
      if (companyDepository === undefined) {
        return []
      }
      const installers = this.installers.filter((installer) => installer.parentId === companyDepository.parentId || installer.parentType === 'Company::Central')
      return installers
    },
    parsedData () {
      return this.data.map((item) => {
        item.categoryOrderName = this.categoryOrders.find((categoryOrder) => categoryOrder.id === item.categoryOrderId).name
        if (item.bagsIds.length > 0) {
          item.bags = this.bags.filter((bag) => item.bagsIds.includes(bag.id))
        }
        item.bagsName = item.bags.map((bag) => bag.reference).join(', ')
        item.stateName = this.$t(`orders.states.${item.state}`) 
        return item
      })
    },
    availableSelectUsers () {
      if (this.object.companyDepositoryId === null) {
        return []
      }
      const companyDepositoryId = this.object.companyDepositoryId
      let companyLocalId = null
      try {
        companyLocalId = this.companyDepositories.find((companyDepository) => companyDepository.id === this.object.companyDepositoryId).parentId
      } catch (error) {
        
      }
      return this.usersSelect.filter((user) => (user.parentId === companyDepositoryId && user.parentType === 'Company::Depository') || 
      (user.parentId === companyLocalId && user.parentType === 'Company::Local') || (user.parentType === 'Company::Central'))
    },
    companyDepositoriesData () {
      // Sort the company depositories by name
      return this.companyDepositories.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    generalOptions () {
      return [
        {
          text: this.$i18n.t(`actions.general.yes`),
          value: true
        },
        {
          text: this.$i18n.t(`actions.general.no`),
          value: false
        }
      ]
    },
    defaultObject () {
      return {
        id: undefined,
        categoryOrderId: 0,
        cycleId: 0,
        productId: 0,
        fieldBatchId: 0,
        installerId: 0,
        executionDate: moment().format('YYYY-MM-DD'),
        sensors: [],
        bluetoothSensors: [],
        bagsQuantity: 0,
        kitsIds: [],
        bags: [],
        bagsIds: [],
        sensorizationType: 'SP',
        contractNumber: '',
        contractNumberComm: '',
        financialAdvances: false,
        weightGaranty: false,
        configuration: {
          email: {
            active: false,
            destinations: []
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: [],
            approvedByCentral: false
          }
        },
        comments: {
          'comment': '',
        }
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    },
    customreFieldBatches () {
      return this.validFields.filter((bag) => bag.companyDepositoryId === this.object.companyDepositoryId)
    },
  
    customerBags () {
      if (this.object.companyDepositoryId === undefined) {
        return []
      }
      if (this.object.categoryOrderId === '3' || this.object.categoryOrderId === '4') {
        // Filter by field batch
        return this.unclosedBags.filter((bag) => bag.fieldBatchId === this.object.fieldBatchId)
      }
      return this.unclosedBags.filter((bag) => bag.companyDepositoryId === this.object.companyDepositoryId)
    },
    parsedCustomerBags () {
      const bags = this.customerBags
      const finalBags = []
      for (var i in bags) {
        // Try to get the kit name
        if (bags[i].kitName !== undefined) {
          finalBags.push({
            id: bags[i].id,
            reference: bags[i].reference + ' - (' + bags[i].kitName + ')',
            kitName: bags[i].kitName
          })
        } else {
          finalBags.push({
            id: bags[i].id,
            reference: bags[i].reference
          })
        }
      }
      return finalBags
    },
    customerKits () {
      return this.kits.filter((kit) => kit.companyDepositoryId === this.object.companyDepositoryId)
    },
    sensorizationTypes () {
      return [
        {
          text: this.$i18n.t('sensorization.sp'),
          value: 'SP'
        },
        {
          text: this.$i18n.t('sensorization.st'),
          value: 'ST'
        }
      ]
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#ordersCard')
    const search = document.getElementById('ordersSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    haveSensorsErrors (sensor) {
      if (sensor.active) {
        // Validate if the sensor has a new name and is not empty and have exact 16 characters and is not repeated
        return this.parseSensorErrors(sensor).length > 0
      }
      return false
    },
    parseSensorErrors (sensor) {
      if (sensor.active) {
        if (sensor.newName === undefined || sensor.newName === '') {
          return [this.$t('helpers.required')]
        }
        if (sensor.newName.length !== 16) {
          return [this.$t('helpers.exactLength', { length: 16 })]
        }
        // Validate on the list of sensors if the name is repeated
        let repeated = this.bagKit.loraSensors.filter((item) => item.newName === sensor.newName)
        if (repeated.length > 1) {
          return [this.$t('helpers.repeated')]
        }
        console.log(repeated)
        repeated = this.bagKit.loraSensors.filter((item) => item.name === sensor.newName)
        if (repeated.length > 0) {
          return [this.$t('helpers.repeated')]
        }
        repeated = this.loraSensors.filter((item) => item.deviceEui === sensor.newName)
        if (repeated.length > 0) {
          return [this.$t('helpers.repeated')]
        }
      }
      return []
    },
    validateIfMoreThanOne (value) {
      this.bagKitName = ''
      this.bagKit = {}
      if (this.object.categoryOrderId === '3') {
        // Get the last value from the array
        const lastValue = value[value.length - 1]
        this.object.bagsIds = [lastValue]
      }
      if (this.object.categoryOrderId === '4') {
        const lastValue = value[value.length - 1]
        this.object.bagsIds = [lastValue]
        // Get the bag kit id
        const bagKit = this.bags.find((bag) => bag.id === lastValue)
        this.bagKitName = bagKit.kitName
        this.bagKit = this.kits.find((kit) => kit.reference === bagKit.kitName)
        this.bagKit.loraSensors = this.bagKit.loraSensors.map((sensor) => {
          return {
            identifier: sensor.identifier,
            name: sensor.name,
            active: false,
            newName: ''
          }
        })
      }
    },
    validateIfMoreThanOneKits (value) {
      console.log(value)
      if (this.object.categoryOrderId === '3') {
        // Get the last value from the array
        const lastValue = value[value.length - 1]
        this.object.kitsIds = [lastValue]
      }
    },
    copyOrder () {
      const copyObject = {
        categoryOrderId: this.object.categoryOrderId,
        cycleId: this.object.cycleId,
        companyDepositoryId: this.object.companyDepositoryId,
        productId: this.object.productId,
        fieldBatchId: this.object.fieldBatchId,
        executionDate: this.object.executionDate,
        bagsQuantity: this.object.bagsQuantity,
        sensorizationType: this.object.sensorizationType,
        installerId: this.object.installerId,
      }
      this.object = Object.assign({}, {
        ...this.defaultObject,
        ...copyObject
      })
      this.adjustBags()
      this.isEditting = true
    },
    showConfiguration () {
      this.configurationModal = true
    },
    customFilter (item, query, arr) {
      const name = item.username.toLowerCase() + '' + this.$i18n.t(`users.${item.parentType.split('::')[1]}`).toLowerCase()
      return name.includes(query)
    },
    handleScroll (event) {
      const elem = document.getElementById('ordersCard')
      const search = document.getElementById('ordersSearchFixed').childNodes[0]
      const searchCard = document.getElementById('ordersSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    checkCompanyDepositoryId () {
      this.object.fieldBatchId = 0
      this.object.bags = []
    },
    selectableKits () {
      const kits = this.ownedKitsLabeled.filter((item) => this.usableKits.includes(item.id))
      const finalKits = [
        ...this.freeKitsLabeled,
        ...kits
      ]
      // Order the kits by name
      finalKits.sort((a, b) => {
        if (a.reference < b.reference) {
          return -1
        }
        if (a.reference > b.reference) {
          return 1
        }
        return 0
      })
      if (this.object.companyDepositoryId === undefined) {
        return finalKits
      }
      const companyLocalId = this.companyDepositories.find((companyDepository) => companyDepository.id === this.object.companyDepositoryId).parentId
      return finalKits.filter((kit) => kit.companyLocalId === companyLocalId)
    },
    selectableBtSensors () {
      const btSensors = this.btSensors.filter((item) => this.usableBtSensors.includes(item.id))
      const finalBtSensors = [
        ...this.freeBtSensors,
        ...btSensors
      ]
      return finalBtSensors
    },
    selectableLoraSensors () {
      const loraSensors = this.loraSensors.filter((item) => this.usableLoraSensors.includes(item.id))
      const finalLoraSensors = [
        ...this.freeLoraSensors,
        ...loraSensors
      ]
      return finalLoraSensors
    },

    adjustBags () {
      if (this.object.bagsQuantity < 0) {
        this.object.bagsQuantity = 0
        return
      }
      const bags = []
      for (var i = 0; i < this.object.bagsQuantity; i++) {
        if (this.object.bags[i] === undefined) {
          bags.push({
            reference: 'Nueva Bolsa',
            estimatedWeight: 0
          })
        } else {
          bags.push(this.object.bags[i])
        }
      }

      this.object.bags = bags
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          this.formModal = true
          this.isEditting = false
          
          this.usableKits = this.object.kitsIds
          this.usableLoraSensors = this.object.sensors
          this.usableBtSensors = this.object.bluetoothSensors
          // Get the bag kit id
          const bagKit = this.bags.find((bag) => bag.id === this.object.bagsIds[0])
          this.bagKitName = bagKit.kitName
          this.bagKit = this.kits.find((kit) => kit.reference === bagKit.kitName)
          this.bagKit.loraSensors = result[objectName.single].changeSensors
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        console.log(error)
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.usableKits = []
      this.usableLoraSensors = []
      this.usableBtSensors = []
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      if (this.object.categoryOrderId === '2') {
        this.object.sensors = []
        this.object.kitsIds = []
        this.object.bluetoothSensors = []
      }
      this.buttonState = true
      this.objectErrors = {}
      const object = Object.assign({}, this.object)
      object.executionDate = moment(this.object.executionDate).unix()
      object.bagsQuantity = parseInt(object.bagsQuantity)
      for (var i = object.bags.length - 1; i >= 0; i--) {
        delete object.bags[i].__typename

        object.bags[i].estimatedWeight = parseFloat(object.bags[i].estimatedWeight)
      }

      if (object.categoryOrderId === '4') {
        object.changeSensors = this.bagKit.loraSensors
      }
      const variables = {
        object: object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename
      delete variables.object.state
      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = {
            ...result
          }
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.dispatch('getKits')
          this.$store.dispatch('getLoraSensors')
          this.$store.dispatch('getBtSensors')

          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    }
  }

}
</script> 